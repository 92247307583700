import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet viscós tant al peu com al capell, sobretot en temps humit. El capell d’aquest és carnós, convexo-cònic de 5 a 15 cm de diàmetre i de color terrós grisenc a gris més fort amb zones blanquinoses que contrasta amb les seves làmines de color blanc quasi pur. Aquestes són gruixudes, decurrents, separades i amb aspecte de cera. El peu és carnós, gruixat, més ample en el centre i atenuat a la base. Es troba estrangulat a la part superior i presenta cap a la base una decoloració en forma d’esquames color avellana o del mateix color que el capell. Les espores són blanques en massa, i en forma d’ametla, de 9-12,5 x 5-7 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      